<template>
  <div class="home">
    <div class="show">
      <div class="video_box">
        <video
          :src="videoList.videoUrl"
          class="video"
          ref="video"
          id="video"
          :autoplay="false"
          :controls="true"
        ></video>
        <div class="text">
          <div class="content">
            <div v-html="videoList.videoContent"></div>
          </div>
        </div>
      </div>
      <div class="banner_box">
        <el-carousel
          class="cus-swiper"
          indicator-position="outside"
          arrow="always"
        >
          <el-carousel-item
            v-for="item in bannerInfo"
            :key="item.id"
            class="banner"
          >
            <div class="top">
              <div>
                <div class="text">{{ item.bannerName }}</div>
                <button v-if="item.showAllStatus==='on'" @click="toTag(item.relateTagId)">Show all</button>
              </div>
            </div>
            <div @click="toDetail(item.relatedProductSku)" class="bottom">
              <img :src="item.bannerUrl" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="filter-group">
      <span class="label">Products</span>
      <el-select
        clearable
        :key="item"
        v-for="(item,index) in [0,1,2]"
        class="select"
        :class="index>0?'ml40':''"
        v-model="valueList[item]"
        placeholder="All"
        value-key="id"
      >
        <div v-if="optionsOne.length > 0">
          <el-option
            v-for="item in optionsOne"
            :key="item.id"
            :label="item.classifyName"
            :value="item"
          ></el-option>
        </div>
      </el-select>
      <el-button :disabled="disableSearch" class="ml40" type="primary" @click="search">
        <span class="search-icon"></span>
        <span>Search</span>
      </el-button>
      <el-button type="primary" @click="reset">Reset</el-button>
    </div>
    <div class="common-gap"></div>

    <div class="bottom-content">
      <template v-if="type==='goods'">
        <template v-if="goodList.length>0">
          <el-row :gutter="30" class="good-list goods">
            <el-col :key="item.id" :span="6" v-for="item in goodList">
              <GoodCard :item="item"></GoodCard>
            </el-col>
          </el-row>

          <div style="text-align: center">
            <el-pagination
              @current-change="pageChange"
              @size-change="sizeChange"
              :current-page="pageNum"
              :page-size="pageSize"
              :page-sizes="[20,40,80]"
              layout="prev, pager, next,sizes"
              :total="total">
            </el-pagination>
          </div>
        </template>
        <el-empty v-else description="No Data"></el-empty>
      </template>
      <template v-else>
        <div class="prod-group" :key="group.name" v-for="group in goodGroup">
          <div class="common-title">
            <div class="_left">
              <span>{{ group.classifyName }} </span>
              <!--              <el-image class="title-image" :src="group.imageUrl" fit="content"></el-image>-->
            </div>
            <div @click="toNext(group.hasNext, group.classifyId)" class="_right">
              Show All >>
            </div>
          </div>
          <el-row :gutter="30" class="good-list">
            <el-col :key="item.id" :span="6" v-for="item in group.productInfoVos">
              <GoodCard :item="item"></GoodCard>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getVideo, getBanner, getAllProductClaasify } from "@/api/home";
import GoodCard from "@/components/GoodCard";
import { firstPageEnter, productByClassifyV2, showAllFunction } from "@/api/good";

export default {
  name: "",
  components: { GoodCard },

  data() {
    return {
      type: "product",
      videoList: {},
      bannerInfo: [],
      classList: [],
      goodGroup: [],
      goodList: [],
      optionsOne: [],
      optionsTwo: [],
      optionsThree: [],
      oneValue: null,
      twoValue: null,
      threeValue: null,
      valueList: ["", "", "", ""],
      queryList: [],
      pageNum: 1,
      pageSize: 20,
      total: 0

    };
  },
  computed: {
    disableSearch() {
      return this.valueList.filter(item => !!item).length === 0;
    }
  },
  mounted() {
    this.loadingData();
  },
  methods: {
    async pageChange(page) {
      this.pageNum = page;
      await this.queryData();
    },
    async sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pageNum = 1;
      await this.queryData();
    },
    async queryData() {
      const res = await productByClassifyV2(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          productClassifyId: this.queryList
        }
      );
      console.log("res", res);
      this.goodList = res.data.list;
      this.total = res.data.total;
    },
    toTag(id) {
      this.$router.push({
        name: "goodListByTagAll",
        params: {
          id: id
        }
      });
    },
    toDetail(id) {
      this.$router.push({
        name: "detail",
        params: {
          id: id
        }
      });
    },
    changeOne(e) {
      if (e.subProductClassify?.length > 0) {
        this.optionsTwo = e.subProductClassify;
      }
      this.oneValue = e;
      this.twoValue = null;
      this.threeValue = null;
    },
    changeTwo(e) {
      if (e.subProductClassify?.length > 0) {
        this.optionsThree = e.subProductClassify;
      }
      this.twoValue = e;
      this.threeValue = null;
    },
    changeThree(e) {
      this.threeValue = e;
    },
    toNext(hasNext, id) {
      if (hasNext) {
        this.$router.push({
          name: "goodList",
          params: {
            id: id
          }
        });
      } else {
        this.$router.push({
          name: "goodListAll",
          params: {
            id: id
          }
        });
      }
    },
    getGoodsListByClassId(id) {
      showAllFunction(id).then((res) => {
        this.goodGroup = res.data;
      });
    },
    search() {
      // console.log('1a:', this.oneValue);
      // console.log('1a:', this.twoValue);
      // console.log('1a:', this.threeValue);
      // if (this.threeValue) {
      //   this.getGoodsListByClassId(this.threeValue.id);
      // } else if (this.twoValue) {
      //   this.getGoodsListByClassId(this.twoValue.id);
      // } else if (this.oneValue) {
      //   this.getGoodsListByClassId(this.oneValue.id);
      // } else {
      //   firstPageEnter().then((res) => {
      //     console.log('good res:', res);
      //     this.goodGroup = res.data;
      //   });
      // }
      this.type = "goods";
      this.queryList = this.valueList.filter(item => !!item.id).map(item => Number(item.id));

      this.queryData();

    },
    reset() {
      // this.oneValue = null;
      // this.twoValue = null;
      // this.threeValue = null;
      // this.optionsTwo = [];
      // this.optionsThree = [];
      this.type = "product";
      this.goodList = [];
      this.valueList = ["", "", "", ""];
      this.queryList = [];

      this.$store.dispatch("queryCart").then(() => {
        firstPageEnter().then((res) => {
          console.log("good res:", res);
          this.goodGroup = res.data;
        });
      });

    },
    loadingData() {
      getVideo().then((res) => {
        if (res.data && res.data.length > 0) {
          this.videoList = res.data[0];
        }
      });
      getBanner({ pageNum: 1 }).then((res) => {
        this.bannerInfo = res.data.list.slice(0, 3);
      });
      getAllProductClaasify().then((res) => {
        console.log(res);
        this.classList = res.data;
        this.optionsOne = res.data;
      });

      this.$store.dispatch("queryCart").then(() => {
        firstPageEnter().then((res) => {
          console.log("good res:", res);
          this.goodGroup = res.data;
        });
      });
    },
    showAllByTag(id) {
      this.$router.push({
        name: "goodListByTagAll",
        params: {
          id: id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  .show {
    margin-top: 10px;
    display: flex;

    .video_box {
      flex: 0 0 50%;
      height: 434px;
      background: url(../assets/images/videoBackground.png) no-repeat;
      background-size: 100% 100%;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #2b2b2b;
        opacity: 0.7;
        z-index: 1;
      }

      .video {
        width: 45%;
        height: 304px;
        position: absolute;
        top: 24px;
        left: 63px;
        z-index: 1;
        background: #000000;
        box-shadow: 0px 3px 9px 0px rgba(50, 14, 14, 0.1);
        border-radius: 12px;
        z-index: 2;
      }

      .text {
        //overflow-y: scroll;
        width: 80%;
        height: 265px;
        position: absolute;
        top: 107px;
        left: 121px;
        border: 2px dashed #ffffff;
        z-index: 1;

        .content {
          padding-right: 10px;
          max-width: 325px;
          height: 156px;
          overflow-y: auto;
          overflow-x: hidden;
          //display: -webkit-box;
          /* 弹性盒子元素垂直排列 */
          //-webkit-box-orient: vertical;
          /* 控制要显示的行数 */
          //-webkit-line-clamp: 7;
          //overflow: hidden;
          //text-overflow: ellipsis;
          position: absolute;
          top: 57px;
          right: 10px;
          font-size: 14px;
          font-weight: normal;
          color: #FFF;
        }
      }
    }

    .banner_box {
      box-sizing: border-box;
      flex: 0 0 50%;
      height: 434px;
      background: url(../assets/images/bannerBackground.png) no-repeat;
      background-size: 100% 100%;
      margin-left: 13px;
      padding: 10px 60px 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #2b2b2b;
        opacity: 0.7;
        z-index: 0;
      }

      .el-carousel {
        width: 100%;
        height: 100%;
      }

      /deep/ .el-carousel__container {
        height: 350px;
      }

      /deep/ .el-carousel__arrow--right {
        right: 0;
      }

      /deep/ .el-carousel__arrow--left {
        left: 0;
      }

      /deep/ .el-carousel__item {
        width: 100% !important;
        height: 350px !important;
        padding: 0 73px;
      }

      .banner {
        .top {
          position: absolute;
          top: 50px;
          left: 150px;
          display: flex;

          > .div {
            text-align: center;
          }


          //display: flex;
          //justify-content: space-between;

          .text {
            //width: 72px;
            //height: 20px;
            font-size: 16px;
            font-weight: normal;
            text-decoration: underline;
            color: #ffffff;
            line-height: 30px;
          }

          button {
            cursor: pointer;
            //width: 120px;
            padding: 0 10px;
            height: 23px;
            background: #43328F;
            border: none;
            border-radius: 6px;
            font-size: 12px;
            font-weight: normal;
            color: #ffffff;
          }
        }

        .bottom {
          display: flex;
          justify-content: center;
          margin: 15px 0 0 200px;

          img {
            cursor: pointer;
            //max-width: 400px;
            height: 300px;
            //margin-left: 53px;
            border-radius: 12px;
          }
        }
      }

      .el-carousel__item {
        width: 312px;
        height: 300px;
        border-radius: 12px;
      }

      /deep/ .el-carousel__arrow {
        border-radius: 10%;
        height: 80px;
        width: 50px;
        font-size: 44px;
        background-color: rgba(31, 45, 61, 0.23);
      }
    }
  }

  .filter-group {
    height: 136px;
    padding: 36px 70px 36px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: 30px;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
    }

    .select {
      width: 280px;
    }

    /deep/ .el-input__inner {
      //width: 400px;
      height: 57px;
      font-size: 20px;
      background: #ffffff;
      border: 2px solid #f1f1f1;
      border-radius: 10px;
    }

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 210px;
      height: 57px;
      border-radius: 5px;
      font-size: 30px;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .bottom-content {
    background: var(--main-color-bg-gray);
    padding: 0 75px 75px;
  }
}

/deep/ .cus-swiper {
  border-radius: 12px;
  overflow: hidden;

  .el-carousel__indicators{
    display: flex;
    margin: 0 20%;
  }

  .el-carousel__indicator{
    flex: 1;
    padding: 20px 10px;
    //padding: 0 20px;
  }

  .el-carousel__button {
    padding: 0 10%;
    width: 100%;
    background-color: var(--main-color-blue);
    height: 10px;
    border-radius: 6px;
    opacity: 1;
  }

  .el-carousel__indicator.is-active button {
    background-color: var(--main-color-blue);
  }

  .el-carousel__indicators--outside button {
    background-color: #fff;
    opacity: 1;
  }
}

.ml40 {
  margin-left: 100px;
}

.goods {
  padding-top: 80px;
  margin-bottom: 40px;
}

/deep/ .el-select .el-input .el-select__caret {
  font-size: 25px;
  font-weight: bold;
  margin-right: 10px;
  color: var(--main-color-blue);
}

.search-icon {
  display: inline-block;
  background: url("~@/assets/images/search.png") no-repeat center;
  width: 35px;
  height: 35px;
  background-size: contain;
  margin-bottom: -8px;
  margin-right: 10px;

}

.el-carousel__indicators--outside button {
  opacity: 1;
  background-color: #fff;
}
</style>
